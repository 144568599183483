import React from "react"
import { Link } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"

import './about.scss'

const About = () => (
  <Layout>
    <SEO title="About" />
    <div className='about'>
      <h1>About</h1>
      <h2>Name</h2>
      <p>Akane</p>
      <h2>Live</h2>
      <p>Nagaoka, Niigata, Japan</p>
      <h2>Occupation</h2>
      <p>Web Developer, Frontend Engineer</p>
      <h2>Language</h2>
      <h3>Frontend</h3>
      <p>HTML, CSS, JavaScript(ECMAScript)</p>
      <h3>Backend</h3>
      <p>Node.js, PHP</p>
      <h3>Other</h3>
      <p>Linux, Japanese</p>
    </div>
    <div className='back-to-home'><Link to='/'>Home</Link></div>
  </Layout>
)

export default About
